
import { defineComponent, ref, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import State from "@/components/reusable/State.vue";
import Address from "@/components/reusable/Address.vue";
import Contents from "@/components/reusable/ContentsLegacy.vue";
import ContentsBuildingModal from "@/components/modals/ContentsBuildingModal.vue";
import ScheduleInfoModal from "@/components/modals/ScheduleInfoModal.vue";
import Generator from "@/components/reusable/LegacyGenerator.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";

export default defineComponent({
  name: "house-insurance-migration-rented-building-component",
  components: {
    Form,
    Field,
    ErrorMessage,
    State,
    Address,
    ImagesUploader,
    Generator,
    Contents,
    ContentsBuildingModal,
    ScheduleInfoModal,
    GoBackButton,
  },
  props: {
    details: Object,
    redirect: String,
    policyId: String,
    packageType: String,
  },
  data() {
    return {
      buildingTypes: BuildingTypes,
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const payload = ref({
      buildingType: props?.details?.building?.buildingType,
      state: props?.details?.building?.state?.publicId,
      localGovernmentArea:
        props?.details?.building?.localGovernmentArea?.publicId,
      ward: props?.details?.building?.ward,
      streetAddress: props?.details?.building?.streetAddress,
      alternativeAccommodation:
        props?.details?.building?.alternativeAccommodation,
      imagesOfBuilding: "",
      houseHoldGoodsAndPersonalEffectsValue:
        props?.details?.building?.houseHoldGoodsAndPersonalEffectsValue,
      cashValue: props?.details?.building?.cashValue,
      thirdPartyLiabilityLimit:
        props?.details?.building?.thirdPartyLiabilityLimit,
      benefitLimit: props?.details?.building?.benefitLimit,
      medicalExpenses: props?.details?.building?.medicalExpenses,
      buildingDescription:
        props?.details?.building?.buildingDescription ??
        props?.details?.building?.buildingType,
      content: props?.details?.content,
      generators: props?.details?.generators,
      // packageType: "TEN",
      // businessClass: "HOM",
      // folioItem: props?.details?.publicId,
    });

    const validationSchema = Yup.object().shape({
      vehicles: Yup.array()
        .of(
          Yup.object().shape({
            owner: Yup.string()
              .required()
              // .min(6)
              .label("Vehicle owner name"),
            vehicleValue: Yup.string()
              .when("package", {
                is: (value) => value != JwtService.getThirdPartyPM(),
                then: Yup.string()
                  // .min(6)
                  .required(),
              })
              .label("Vehicle value"),
            package: Yup.mixed()
              .required()
              .label("Cover"),
            vehicleMake: Yup.mixed()
              .required()
              .label("Vehicle brand"),
            vehicleModel: Yup.string()
              .required()
              .label("Vehicle model"),
            registrationNumber: Yup.string()
              .required()
              // .min(8)
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string()
              .required()
              .min(1)
              .label("Engine number"),
            bodyType: Yup.string()
              .required()
              .label("Body type"),
            vehicleColor: Yup.string()
              .required()
              .label("Vehicle colour"),
            vehicleLicense: Yup.mixed()
              .required()
              .label("Vehicle license"),
          })
        )
        .strict(),
    });

    const updateHouseholderInformation = () => {
      console.clear();

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData(document.forms[0]);
            formData.append("folioItem", props?.details?.publicId);
            formData.append("businessClass", "HOM");
            formData.append("packageType", "TEN");
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${variables.POLICIES_ROUTE}/${props?.policyId}/folio-item`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: props?.redirect,
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const numberFormatter = () => {
      const ids = [
        "benefit-limit",
        "alternative-accommodation-value",
        "general-household-goods",
        "medical-expenses-premium",
        "owned-third-party-value",
        "owned-cash-value",
      ];
      ids.forEach((id: string) => {
        variables.numberFormatter(id);
      });
    };

    onMounted(() => {
      numberFormatter();
    });

    return {
      updateHouseholderInformation,
      validationSchema,
      submitButton1,
      payload,
    };
  },
});
