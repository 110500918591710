
import { defineComponent } from "vue";
import Phone from "@/components/reusable/PhoneLegacy.vue";
import Laptop from "@/components/reusable/LaptopLegacy.vue";
import Jewelry from "@/components/reusable/JewelryLegacy.vue";
import Camera from "@/components/reusable/CameraLegacy.vue";
import MusicalInstrument from "@/components/reusable/MusicalInstrumentLegacy.vue";
import Inverter from "@/components/reusable/InverterLegacy.vue";

export default defineComponent({
  name: "house-insurance-default-contents-component",
  components: { Phone, Laptop, Jewelry, Camera, MusicalInstrument, Inverter },
  props: {
    phones: Array,
    laptops: Array,
    cameras: Array,
    inverters: Array,
    musicalInstruments: Array,
    jewelries: Array,
  },
});
