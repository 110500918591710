
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import MusicalInstruments from "@/views/reusable/buy-insurance/house-insurance/musical-instruments.json";
import MusicalInstrumentBrands from "@/views/reusable/buy-insurance/house-insurance/musical-instruments-brands.json";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "edit-musical-content-component",
  components: { Field, ErrorMessage },
  props: {
    musicalInstruments: Array,
  },
  data() {
    return {
      musicalLists: MusicalInstruments,
      musicalBrands: MusicalInstrumentBrands,
      musicalInstrumentDetails: [
        {
          imageOfReceipt: "",
          brand: "",
          type: "",
          value: 0.0 as number,
        },
      ],
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    if (
      this.musicalInstruments !== undefined &&
      !this.isEmptyArray(this.musicalInstruments)
    ) {
      this.musicalInstrumentDetails = [...this.musicalInstruments] as any;
    }
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
    addDynamicField() {
      this.musicalInstrumentDetails.push({
        imageOfReceipt: "",
        brand: "",
        type: "",
        value: 0.0 as number,
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      $(".remove-music-" + counter).remove();
    },
    imageSelected(event, position) {
      this.musicalInstrumentDetails[position].imageOfReceipt =
        event.target.files[0];
    },
  },
});
