
import { defineComponent, ref, onMounted } from "vue";
import { Form, Field } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import Insurer from "@/components/reusable/Insurer.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "motor-insurance-house-insurance-edit-policy-information-component",
  components: { Form, Field, Insurer, GoBackButton },
  props: {
    details: Object,
    redirect: String,
  },
  setup(props) {
    const { unformatNumber } = ResusableFunctions();
    const covers = ref([]);
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const underwriters = ref([]);
    const clients = ref([
      {
        publicId: "",
        profilePublicId: "",
        fullName: "",
        firstName: "",
        lastName: "",
      },
    ]);

    const payload = ref({
      clientPublicId: props?.details?.policySummary?.profilePublicId,
      policyNumber: props?.details?.policySummary?.number,
      businessType: props?.details?.policySummary?.businessTypeShortName,
      policyStartDate: props?.details?.policySummary?.startDate,
      policyEndDate: props?.details?.policySummary?.expiryDate,
      underwriterPublicId: props?.details?.policySummary?.leadInsurerPublicId,
      paymentReceipt: "",
      policyDocument: "",
      brokerReference: props?.details?.policySummary?.reference,
      totalPremium: props?.details?.policySummary?.premium,
      totalSumInsured: props?.details?.policySummary?.sumInsured,
      hasPolicyDocument: props?.details?.policySummary?.hasPolicyDocument,
      hasPaymentReceipt: props?.details?.policySummary?.hasPaymentReceipt,
      packageType:
        props?.details?.policySummary?.businessTypeShortName == "HOO"
          ? "OWN"
          : "TEN",
    });

    const getUnderwriterId = (underwriterId: string) => {
      payload.value.underwriterPublicId = underwriterId;
    };

    const policyImageSelected = (event, name: string) => {
      payload.value[name] = event.target.files[0];
    };

    const getCovers = (businessType: string) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.INSURANCE_PACKAGES_ROUTE}/${businessType}`)
          .then(({ data }) => {
            covers.value = data.data;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const validationSchema = Yup.object().shape({
      clientPublicId: Yup.string()
        .required()
        .label("Client"),
      businessType: Yup.string()
        .required()
        .label("Business type"),
      policyNumber: Yup.string()
        .required()
        .label("Policy number"),
      brokerReference: Yup.string()
        .required()
        .label("Policy Reference"),
      totalPremium: Yup.string()
        .required()
        .label("Total premium"),
      totalSumInsured: Yup.string()
        .required()
        .label("Total sum insured"),
      policyStartDate: Yup.date()
        .required()
        .label("Policy start date"),
      policyEndDate: Yup.date()
        .required()
        .label("Policy end date"),
    });

    const updatePolicyInformation = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            formData.append("clientPublicId", values["clientPublicId"]);
            formData.append("paymentReceipt", values["paymentReceipt"]);
            formData.append("policyDocument", values["policyDocument"]);
            formData.append("policyStartDate", values["policyStartDate"]);
            formData.append("policyEndDate", values["policyEndDate"]);
            formData.append("brokerReference", values["brokerReference"]);
            formData.append("policyNumber", values["policyNumber"]);
            formData.append("packageType", payload.value.packageType);
            formData.append("businessClass", "HOM");
            formData.append("businessType", payload.value.businessType);

            formData.append(
              "totalPremium",
              unformatNumber(values["totalPremium"])
            );
            formData.append(
              "underwriterPublicId",
              payload.value.underwriterPublicId
            );

            if (payload.value.totalSumInsured !== undefined) {
              // console.log("totalSumInsured: ", payload.value.totalSumInsured);

              formData.append(
                "totalSumInsured",
                unformatNumber(payload.value.totalSumInsured)
              );
            }
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${variables.POLICIES_ROUTE}/${props?.details?.policySummary?.publicId}/summary`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: props?.redirect,
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const getUnderwriters = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_UNDERWRITERS_LIST_ROUTE)
          .then(({ data }) => {
            underwriters.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getLegacyClients = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.LEGACY_CLIENT)
          .then(({ data }) => {
            clients.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const numberFormatter = () => {
      const ids = ["total-premium-value", "total-sum-insured"];
      ids.forEach((id: string) => {
        variables.numberFormatter(id);
      });
    };

    onMounted(() => {
      numberFormatter();
      getUnderwriters();
      getLegacyClients();
    });

    return {
      getCovers,
      getUnderwriterId,
      updatePolicyInformation,
      policyImageSelected,
      validationSchema,
      submitButton1,
      underwriters,
      clients,
      payload,
    };
  },
});
