import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Phone = _resolveComponent("Phone")!
  const _component_Laptop = _resolveComponent("Laptop")!
  const _component_Jewelry = _resolveComponent("Jewelry")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_MusicalInstrument = _resolveComponent("MusicalInstrument")!
  const _component_Inverter = _resolveComponent("Inverter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Phone, { phones: _ctx.phones }, null, 8, ["phones"]),
    _createVNode(_component_Laptop, { laptops: _ctx.laptops }, null, 8, ["laptops"]),
    _createVNode(_component_Jewelry, { jewelries: _ctx.jewelries }, null, 8, ["jewelries"]),
    _createVNode(_component_Camera, { cameras: _ctx.cameras }, null, 8, ["cameras"]),
    _createVNode(_component_MusicalInstrument, { "musical-instruments": _ctx.musicalInstruments }, null, 8, ["musical-instruments"]),
    _createVNode(_component_Inverter, { inverters: _ctx.inverters }, null, 8, ["inverters"])
  ]))
}