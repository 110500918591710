
import { defineComponent, ref } from "vue";
import variables from "@/router/api";
import EditOwnedBuilding from "@/components/migration/packages/house-insurance/edit/OwnedBuilding.vue";
import EditRentedBuilding from "@/components/migration/packages/house-insurance/edit/RentedBuilding.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "house-insurance-migration-information-component",
  components: { EditOwnedBuilding, EditRentedBuilding },
  props: {
    details: Object,
    redirect: String,
  },
  setup(props) {
    const { generateUUID } = ResusableFunctions();
    const payload = ref({
      buildings: props?.details?.items ?? [],
      details: {},
      displayOwned: false,
      displayRented: false,
      key: "",
      packageType:
        props?.details?.policySummary?.businessTypeShortName == "HOO"
          ? "OWN"
          : "TEN",
      covers: [],
    });

    const addCommaToNumberString = (amount: string | number) => {
      return variables.addCommaToNumberString(amount);
    };

    const editHouseDetails = (details: any) => {
      const businessShortName =
        props?.details?.policySummary?.businessTypeShortName;
      payload.value.details = details;
      payload.value.displayOwned = businessShortName === "HOO" ? true : false;
      payload.value.displayRented = businessShortName !== "HOO" ? true : false;
      payload.value.key = generateUUID();
    };

    return {
      addCommaToNumberString,
      editHouseDetails,
      payload,
    };
  },
});
