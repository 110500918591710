
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import Phones from "@/views/reusable/buy-insurance/house-insurance/phone-brands.json";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "phone-content-legacy-component",
  components: { Field, ErrorMessage },
  props: {
    phones: Array
  },
  data() {
    return {
      phoneLists: Phones,
      phoneDetails: [
        {
          brand: "",
          model: "",
          serialNumber: "",
          imageOfReceipt: "",
          value: 0.0
        }
      ]
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray
    };
  },
  created() {
    if (this.phones !== undefined && !this.isEmptyArray(this.phones)) {
      this.phoneDetails = [...this.phones] as any;
    }
  },
  methods: {
    premium(event) {
      // Format the value field with commas
      const value = event.target.value.replace(/\D/g, "");
      const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = formattedValue));
    },
    addDynamicField() {
      this.phoneDetails.push({
        brand: "",
        model: "",
        serialNumber: "",
        imageOfReceipt: "",
        value: 0.0
      });
    },
    removeDynamicField(counter) {
      $(".remove-phone-" + counter).remove();
    },
    phoneReceiptSelected(event, index) {
      this.phoneDetails[index].imageOfReceipt = event.target.files[0];
    }
  }
});
